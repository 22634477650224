// 封装request模块
import { Toast } from 'vant';
import axios from 'axios'
import JSONBig from 'json-bigint'
//import store from '@/store'




const instance = axios.create({
   //baseURL: 'http://192.168.10.231:8080',//'http://localhost:8080',
  baseURL: '/',
  transformResponse: [function (data) {
    
     try {
      return JSONBig.parse(data)
     } catch (error) {
       return data
     }
  }]
})

// 请求拦截器注入Token
instance.interceptors.request.use(function (config) {
  // debugger
  // if (store.state.user.token) {
  //   // config.headers['Authorization'] = `Bearer ${store.state.user.token}`
  //   config.headers.Authorization = `Bearer ${store.state.user.token}`
  // }
  return config
}, function (error) {
  return Promise.reject(error)
})

//响应拦截器
instance.interceptors.response.use(function (response) {
  try {
    return response
  } catch (error) {
    return response
  }
}, async function (error) {
   if(error.response && error.response.status>=500){
    Toast("访问服务器异常，请稍后再试！");
    Toast.allowMultiple();//默认单例模式
  }
  else if(error.response && error.response.status==413){
    Toast("上传文件过大,"+error.message);
    Toast.allowMultiple();//默认单例模式
  }
  else{
    Toast("请求异常："+error.message);
    Toast.allowMultiple();//默认单例模式
  }
  return Promise.reject(error)
})

export default instance
