import request from '@/utils/request'


// 获取
export function getDevice (id,uid) {
  return request({
    url: `/api/QuAnswer/GetDevice2?id=${id}&uid=${uid}`
  })
}
//获取问卷调查
export function getQuestionnaire(id,deviceId){
  return request({
    url: `/api/QuAnswer/GetQuestionnaire?id=${id}&deviceId=${deviceId}`
  })
}

//获取课程评价
export function getQuestionnaireCourse(id,deviceId,uid){
  return request({
    url: `/api/QuAnswer/GetQuestionnaireForCourse?id=${id}&cid=${deviceId}&uid=${uid}`
  })
}
export function getPoster(id,uid,deviceId){
  return request({
    url: `/api/QuAnswer/GetPoster2?qid=${id}&uid=${uid}&did=${deviceId}&type=3`
  })
}

export function getPoster2(id,uid,deviceId,type){
  return request({
    url: `/api/QuAnswer/GetPoster2?qid=${id}&uid=${uid}&did=${deviceId}&type=${type}`
  })
}

export function GetActionList(id){
  return request({
    url: `/api/Questionnaire/GetActionList?id=${id}`
  })
}

export function AddAnswer (params) {
  return request({
    url: '/api/QuAnswer/Add',
    data: { ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}

export function AdPost (params) {
  return request({
    url: '/api/QuAnswer/AdPost',
    data: { ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}

export function uploadfileplus (params,baseDir) {
  return request({
    url: '/api/Upload/h5fileplus?baseDir='+baseDir,
    data:params,
    method: 'post',
  //   header:{
  //     'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
  //   },
  headers: {
      "Content-Type": "multipart/form-data" 
   },
     transformRequest: [
         (params) => params,
     ]

    
  })
}